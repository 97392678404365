export const BankingPartnersList = () => import('../../components/BankingPartnersList.vue' /* webpackChunkName: "components/banking-partners-list" */).then(c => wrapFunctional(c.default || c))
export const ContentWrapper = () => import('../../components/ContentWrapper.vue' /* webpackChunkName: "components/content-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const MinucredActions = () => import('../../components/MinucredActions.vue' /* webpackChunkName: "components/minucred-actions" */).then(c => wrapFunctional(c.default || c))
export const MinucredCard = () => import('../../components/MinucredCard.vue' /* webpackChunkName: "components/minucred-card" */).then(c => wrapFunctional(c.default || c))
export const MinucredHeader = () => import('../../components/MinucredHeader.vue' /* webpackChunkName: "components/minucred-header" */).then(c => wrapFunctional(c.default || c))
export const Whatsapp = () => import('../../components/Whatsapp.vue' /* webpackChunkName: "components/whatsapp" */).then(c => wrapFunctional(c.default || c))
export const WhatsappButton = () => import('../../components/WhatsappButton.vue' /* webpackChunkName: "components/whatsapp-button" */).then(c => wrapFunctional(c.default || c))
export const TelaCPF = () => import('../../components/fluxo-ok/TelaCPF.vue' /* webpackChunkName: "components/tela-c-p-f" */).then(c => wrapFunctional(c.default || c))
export const SearchableSelect = () => import('../../components/forms/searchable-select.vue' /* webpackChunkName: "components/searchable-select" */).then(c => wrapFunctional(c.default || c))
export const AppleStore = () => import('../../components/icons/AppleStore.vue' /* webpackChunkName: "components/apple-store" */).then(c => wrapFunctional(c.default || c))
export const GooglePlay = () => import('../../components/icons/GooglePlay.vue' /* webpackChunkName: "components/google-play" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/shared/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const LoanOption = () => import('../../components/simulation/LoanOption.vue' /* webpackChunkName: "components/loan-option" */).then(c => wrapFunctional(c.default || c))
export const TelaExibirDetalhes = () => import('../../components/fluxo-ok/AutoContratacao/TelaExibirDetalhes.vue' /* webpackChunkName: "components/tela-exibir-detalhes" */).then(c => wrapFunctional(c.default || c))
export const TelaExibirPacotes = () => import('../../components/fluxo-ok/AutoContratacao/TelaExibirPacotes.vue' /* webpackChunkName: "components/tela-exibir-pacotes" */).then(c => wrapFunctional(c.default || c))
export const BankNotAuthorized = () => import('../../components/simulation/errors/BankNotAuthorized.vue' /* webpackChunkName: "components/bank-not-authorized" */).then(c => wrapFunctional(c.default || c))
export const NonOptingUser = () => import('../../components/simulation/errors/NonOptingUser.vue' /* webpackChunkName: "components/non-opting-user" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/ui/slider/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const SliderStep = () => import('../../components/ui/slider/SliderStep.vue' /* webpackChunkName: "components/slider-step" */).then(c => wrapFunctional(c.default || c))
export const TelaContratacaoBancoBV = () => import('../../components/fluxo-ok/AutoContratacao/BancoBV/TelaContratacaoBancoBV.vue' /* webpackChunkName: "components/tela-contratacao-banco-b-v" */).then(c => wrapFunctional(c.default || c))
export const TelaExibirSucessoBancoBV = () => import('../../components/fluxo-ok/AutoContratacao/BancoBV/TelaExibirSucessoBancoBV.vue' /* webpackChunkName: "components/tela-exibir-sucesso-banco-b-v" */).then(c => wrapFunctional(c.default || c))
export const TelaContratacaoBancoPan = () => import('../../components/fluxo-ok/AutoContratacao/BancoPan/TelaContratacaoBancoPan.vue' /* webpackChunkName: "components/tela-contratacao-banco-pan" */).then(c => wrapFunctional(c.default || c))
export const TelaExibirSucessoBancoPan = () => import('../../components/fluxo-ok/AutoContratacao/BancoPan/TelaExibirSucessoBancoPan.vue' /* webpackChunkName: "components/tela-exibir-sucesso-banco-pan" */).then(c => wrapFunctional(c.default || c))
export const TelaContratacaoC6 = () => import('../../components/fluxo-ok/AutoContratacao/C6/TelaContratacaoC6.vue' /* webpackChunkName: "components/tela-contratacao-c6" */).then(c => wrapFunctional(c.default || c))
export const TelaExibirSuccessoC6 = () => import('../../components/fluxo-ok/AutoContratacao/C6/TelaExibirSuccessoC6.vue' /* webpackChunkName: "components/tela-exibir-successo-c6" */).then(c => wrapFunctional(c.default || c))
export const TelaContratacaoFacta = () => import('../../components/fluxo-ok/AutoContratacao/Facta/TelaContratacaoFacta.vue' /* webpackChunkName: "components/tela-contratacao-facta" */).then(c => wrapFunctional(c.default || c))
export const TelaExibirSuccessoFacta = () => import('../../components/fluxo-ok/AutoContratacao/Facta/TelaExibirSuccessoFacta.vue' /* webpackChunkName: "components/tela-exibir-successo-facta" */).then(c => wrapFunctional(c.default || c))
export const DadosBancarios = () => import('../../components/fluxo-ok/AutoContratacao/ICred/DadosBancarios.vue' /* webpackChunkName: "components/dados-bancarios" */).then(c => wrapFunctional(c.default || c))
export const DadosEndereco = () => import('../../components/fluxo-ok/AutoContratacao/ICred/DadosEndereco.vue' /* webpackChunkName: "components/dados-endereco" */).then(c => wrapFunctional(c.default || c))
export const DadosPessoais = () => import('../../components/fluxo-ok/AutoContratacao/ICred/DadosPessoais.vue' /* webpackChunkName: "components/dados-pessoais" */).then(c => wrapFunctional(c.default || c))
export const TelaContratacaoIcred = () => import('../../components/fluxo-ok/AutoContratacao/ICred/TelaContratacaoIcred.vue' /* webpackChunkName: "components/tela-contratacao-icred" */).then(c => wrapFunctional(c.default || c))
export const TelaExibirSucessoIcred = () => import('../../components/fluxo-ok/AutoContratacao/ICred/TelaExibirSucessoIcred.vue' /* webpackChunkName: "components/tela-exibir-sucesso-icred" */).then(c => wrapFunctional(c.default || c))
export const TelaContratacaoSafra = () => import('../../components/fluxo-ok/AutoContratacao/Safra/TelaContratacaoSafra.vue' /* webpackChunkName: "components/tela-contratacao-safra" */).then(c => wrapFunctional(c.default || c))
export const TelaExibirSuccessoSafra = () => import('../../components/fluxo-ok/AutoContratacao/Safra/TelaExibirSuccessoSafra.vue' /* webpackChunkName: "components/tela-exibir-successo-safra" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
